import { hideModal, isValueFilled, runModal } from '@/core/utils/helper';
import SalutationsConstants from '@/modules/contacts/constants/salutations.constants';
import { mapState, mapActions, mapGetters } from 'vuex';
import { isProduction } from '@/modules/users/stores/getters';

export default {
  data() {
    return {
      cdnURL: process.env.VUE_APP_CDN_BASE,
    };
  },

  computed: {
    ...mapState('Common', ['activeModals']),
    ...mapGetters('Common', ['isModalActive']),
  },

  methods: {
    ...mapActions('Common', ['openLsModal', 'closeLsModal']),

    isReleased(module, companyId) {
      // TODO develop kontrolü vs de burada yapılabilir
      // 242 and 327 canlıda olmadığı için problem çıkarmaz ama normalde develop ve canlı için ayrı ayrı kontrol yapılmalıydı
      switch (module) {
        case 'scale_sliding':
          return [509, 242, 327, 302, 442, 410, 469, 276, 329, 459, 294].includes(companyId);
        case 'sliding_feedback':
          return [509, 242, 327, 302, 442, 410, 469, 276, 329, 459, 294].includes(companyId);
        case 'interest_level_list':
          return [509, 242, 327, 302, 442, 410, 469, 276, 329, 459].includes(companyId);
        case 'calendar_warning':
          return [509, 242, 327].includes(companyId);
        default:
          return false;
      }
    },
    getAddress(value) {
      let Address1;
      if (value.addressPostCode.number) {
        Address1 = value.addressPostCode.number;
      } else if (value.addressPostCode.addressline1) {
        Address1 = value.addressPostCode.addressline1;
      } else {
        Address1 = value.addressPostCode.buildingname;
      }
      if (value.addressPostCode.addressline1.search('Flat') !== -1) {
        Address1 = `${Address1}  ${value.addressPostCode.addressline1}`;
      }
      const fullAddress = {
        address_1: Address1,
        address_2: value.addressPostCode.street,
        address_3: value.addressPostCode.dependentlocality,
        address_4: value.addressPostCode.posttown,
        address_5: value.addressPostCode.county,
        postcode: value.addressPostCode.postcode,
        display_name: value.addressPostCode.summaryline,
        country_id: 1,
      };
      if (value.addressGoogle && value.addressGoogle.geometry && value.addressGoogle.geometry.location && value.addressGoogle.geometry.location.lng) {
        fullAddress.long = value.addressGoogle.geometry.location.lng;
      }
      if (value.addressGoogle && value.addressGoogle.geometry && value.addressGoogle.geometry.location && value.addressGoogle.geometry.location.lat) {
        fullAddress.lat = value.addressGoogle.geometry.location.lat;
      }
      this.fullAddress = { ...fullAddress };
    },
    generateFullAddress(address) {
      if (address) {
        if (address.full_address) {
          return address.full_address;
        }
        if (address.display_name) {
          if (address.display_name.includes(`${address.postcode}, ${address.postcode}`)) {
            return address.display_name.replace(`, ${address.postcode}`, '');
          }
          return address.display_name;
        }
      }
      return ' ';
    },
    generateDisplayAddress(address, join = false) {
      if (join) {
        const addressKeys = ['address_1', 'address_2', 'address_3', 'address_4', 'address_5', 'postcode'];
        const addressData = [];
        addressKeys.forEach((part) => {
          if (address[part]) {
            addressData.push(address[part]);
          }
        });
        return addressData.join(', ');
      }
      return this.generateFullAddress(address);
    },
    getPropertyAddressToUseInContact(value) {
      const address = {};
      if (value) {
        // controlling all fields across field does not exist problem.
        if (value.address_1) {
          address.address_1 = value.address_1;
        }
        if (value.address_2) {
          address.address_2 = value.address_2;
        }
        if (value.address_3) {
          address.address_3 = value.address_3;
        }
        if (value.address_4) {
          address.address_4 = value.address_4;
        }
        if (value.address_5) {
          address.address_5 = value.address_5;
        }
        if (value.country_id) {
          address.country_id = value.country_id;
        }
        if (value.full_address) {
          address.display_name = value.full_address;
        }
        if (value.display_name) {
          address.display_name = value.display_name;
        }
        if (value.postcode) {
          address.postcode = value.postcode;
        }
        if (value.lat) {
          address.lat = value.lat;
        }
        if (value.long) {
          address.long = value.long;
        }
      }
      return address;
    },
    zeroAdapter(data, type) {
      const PHONE_TYPES = [1, 7];
      /**
       * if type is telephone and its content is not empty and,
       * if first character of value is not 0 or 0 or + and,
       * if value length is 10.
       * add 0 to beginning of phone number.
       */
      if (PHONE_TYPES.some(t => t === type) && this.checkValue(data.value)) {
        if ([0, '0', '+'].some(t => t !== data.value[0]) && data.value.length === 10) {
          data.value = `0${data.value}`;
        }
      }
      return data.value;
    },
    getAllCommunicationInfoByMethod(communications, type) {
      const array = [];
      communications.forEach((item) => {
        if (item.communication_method_id === type) {
          array.push(item);
        }
      });
      return array;
    },
    communicationInfoByMethod(communication, type) {
      const filterMethod = communication.filter(
        c => c.communication_method_id === type,
      );
      if (filterMethod.length > 0) {
        return this.zeroAdapter(filterMethod[0], type);
      }
      return ' ';
    },
    communicationAllInfoByMethod(communication, type) {
      const filterMethod = communication.filter(
        c => c.communication_method_id === type,
      );
      if (filterMethod.length > 0) {
        return filterMethod[0];
      }
      return ' ';
    },
    // to get only value information of preferred communication
    getPreferredCommunicationInfo(communication, type) {
      if (communication) {
        const filterMethod = communication.filter(
          c => c.communication_method_id === type && c.preferred === true,
        );
        if (filterMethod.length > 0) {
          return this.zeroAdapter(filterMethod[0], type);
        }
        return this.communicationInfoByMethod(communication, type);
      }
      return ' ';
    },
    // to get all information of preferred communication
    getPreferredCommunicationsAllInfo(communication, type) {
      const filterMethod = communication.filter(
        c => c.communication_method_id === type && c.preferred === true,
      );
      if (filterMethod.length > 0) {
        return filterMethod[0];
      }
      return this.communicationAllInfoByMethod(communication, type);
    },
    communicationControlValidator(communication, type) {
      const filterMethod = communication.filter(
        c => c.communication_method_id === type,
      );
      return !!(filterMethod.length > 0 && filterMethod[0] && filterMethod[0].value);
    },
    itemsContains(array, n) {
      return array.indexOf(n) > -1;
    },
    randStr(len) {
      let s = '';
      while (s.length < len) {
        s += Math.random()
          .toString(36)
          .substr(2, len - s.length);
      }
      return s;
    },
    currencyFormat(num) {
      if (num) return `£${num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
      return '£0';
    },
    doTrueToShowPopup(data) {
      this[data].show = true;
    },
    doFalseToHidePopup(data) {
      this[data].show = false;
    },
    runModal(id) {
      runModal(id);
    },
    hideModal(id) {
      hideModal(id);
    },
    async runComponentModal(data) {
      await this.doTrueToShowPopup(data);
      this.runModal(`#${this[data].selector}`);
    },
    async destroyComponentModal(data) {
      await this.hideModal(`#${this[data].selector}`);
      this.doFalseToHidePopup(data);
      if (this[data].data) {
        this[data].data = false;
      }
    },
    validateFields(object, fields) {
      let result = true;
      try {
        fields.forEach((field) => {
          if (!this.checkValue(object[field])) {
            throw `${field.charAt(0).toUpperCase() + field.slice(1)} field is required!`;
          }
        });
      } catch (e) {
        result = false;
        this.errorMessage(e);
      }
      return result;
    },
    getSalutation(contact) {
      // if salutation is not Unknown.
      let salutationId;
      if (Object.prototype.hasOwnProperty.call(contact, 'salutation_id')) {
        salutationId = contact.salutation_id;
      } else if (Object.prototype.hasOwnProperty.call(contact, 'salutation')) {
        salutationId = contact.salutation.salutation_id;
      }

      if (salutationId && salutationId !== 16) {
        const contactSalutation = SalutationsConstants.salutations.find(salutation => salutation.salutation_id === salutationId);
        if (contactSalutation) {
          return contactSalutation.name;
        }
      }
      return null;
    },
    produceKey() {
      return Math.random().toString(36).substr(2, 9);
    },
    produceRandomInt(min, max) {
      return Math.floor(
        Math.random() * (max - min) + min,
      );
    },
    makeCapitalize(e) {
      const val = e.target.value;
      const arr = val.split(' ').map(item => (item ? item[0].toUpperCase() + item.slice(1) : ''));
      e.target.value = arr.join(' ');
    },
    returnMakeCapitalize(e) {
      return e.charAt(0).toUpperCase() + e.slice(1);
    },
    numberFormat(num) {
      if (num) return `${num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
      return '0';
    },
    checkValue(value, options = {}) {
      return isValueFilled(value, options);
    },
    isCanBeNumeric(n) {
      // eslint-disable-next-line no-restricted-globals
      return typeof (n) !== 'boolean' && !isNaN(n) && !!isValueFilled(n);
    },
    calculatePlural(count, pluralType = 's') {
      return count > 1 ? pluralType : '';
    },
    percentage(num, per) {
      return (num / 100) * per;
    },
    formatPrice(value) {
      const price = value;
      const formattedOutput = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
      });
      return formattedOutput.format(price);
    },
    mobilePhoneIsExists(communicationInfos) {
      if (!communicationInfos) return false;

      const mobilePhoneInfo = communicationInfos.find(o => o.communication_method_id === 1);
      return mobilePhoneInfo ? mobilePhoneInfo.value : false;
    },
  },
};
