import dayjs from 'dayjs';
import BankHolidaysConstants from '@/modules/common/constants/bankHolidays.constants';

const relativeTime = require('dayjs/plugin/relativeTime');
const LocalizedFormat = require('dayjs/plugin/localizedFormat');
const updateLocale = require('dayjs/plugin/updateLocale');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const weekOfYear = require('dayjs/plugin/weekOfYear');
const isBetween = require('dayjs/plugin/isBetween');
const advancedFormat = require('dayjs/plugin/advancedFormat');
const isoWeek = require('dayjs/plugin/isoWeek');
const localeData = require('dayjs/plugin/localeData');


dayjs.extend(advancedFormat);


const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
const UI_DATE_FORMAT = 'DD/MM/YYYY';
const HOUR_FORMAT = 'HH:mm';
const SECONDS_FORMAT = ':ss';

dayjs.extend(updateLocale);
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);
dayjs.extend(localeData);

export const fromNow = (date) => {
  const format = dayjs(date).fromNow(true);
  if (format === 'a day ago') {
    return '1 day ago';
  }
  if (format === 'a month ago') {
    return '1 month';
  }
  if (format === 'a year ago') {
    return '1 year';
  }
  return format;
};

/**
 * @description Currently supports day and hour.
 * @param dateOne
 * @param dateTwo
 * @param unit
 * @param returnWithoutUnit {boolean} if it is true, it doesnt return with unit
 * @param isExactDiff {boolean} if it is true, it doesnt check minimal values
 * @return {string|*}
 */
export const diffBetweenTwoDates = (dateOne, dateTwo, unit = 'day', {
  returnWithoutUnit, isExactDiff,
} = { returnWithoutUnit: false, isExactDiff: false }) => {
  let result;
  try {
    result = dateOne.diff(dateTwo, unit);
  } catch (e) {
    result = dayjs(dateOne).diff(dayjs(dateTwo), unit);
  }

  if (result === 0 && unit === 'day' && !isExactDiff) {
    return diffBetweenTwoDates(dateOne, dateTwo, 'hour');
  }

  if (result === 0 && unit === 'hour' && !isExactDiff) {
    result += 1;
  }

  if (!returnWithoutUnit) {
    return `${result} ${unit}${result > 1 ? 's' : ''}`;
  }
  return result;
};

export const dateFormat = (date, type) => {
  const DATE_TIME = 'DD/MM/YYYY HH:mm:ss';
  const DEFAULT_FORMAT = 'DD/MM/YYYY';
  const FULL_FORMAT = 'ddd, MMM D, YYYY h:mm';
  const TIME = 'HH:mm';
  const MONTH = 'MMM';
  const YEAR = 'YYYY';
  const DAY = 'ddd';
  let format;
  if (type === 'dateTime') {
    format = DATE_TIME;
  } else if (type === 'default') {
    format = DEFAULT_FORMAT;
  } else if (type === 'fullFormat') {
    format = FULL_FORMAT;
  } else if (type === 'time') {
    format = TIME;
  } else if (type === 'month') {
    format = MONTH;
  } else if (type === 'year') {
    format = YEAR;
  } else if (type === 'day') {
    format = DAY;
  }
  return dayjs(date).format(format);
};

export const uiDateFormat = (date, time = false, second = false) => {
  let format = 'DD/MM/YYYY';
  if (time) {
    format += ' HH:mm';
  }
  if (second) {
    format += ':ss';
  }
  return dayjs(date).format(format);
};

export const backendDateFormat = (date, time = false, second = false) => {
  let format = 'YYYY-MM-DD';
  if (time) {
    format += ' HH:mm';
  }
  if (second) {
    format += ':ss';
  }
  return dayjs(date).format(format);
};

export const unixDateFormat = (date, time = false, second = false) => {
  let format = 'YYYY-MM-DD';
  if (time) {
    format += ' HH:mm';
  }
  if (second) {
    format += ':ss';
  }
  return dayjs.unix(date).format(format);
};

export const strictUiDateFormat = (date, time = false, second = false) => {
  let firstFormat = BACKEND_DATE_FORMAT;
  let format = UI_DATE_FORMAT;
  if (time) {
    format += ` ${HOUR_FORMAT}`;
    firstFormat += ` ${HOUR_FORMAT}`;
  }
  if (second) {
    format += SECONDS_FORMAT;
    firstFormat += SECONDS_FORMAT;
  }
  return dayjs(date, firstFormat).format(format);
};


export const localizeDateFormat = (date, options = {
  format: 'ddd H:MM',
}) => {
  dayjs.updateLocale('en', {
    weekdays: [
      'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',
    ],
    weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthsShort: [
      'Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'June',
      'July', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.',
    ],
  });

  dayjs.extend(LocalizedFormat);
  return dayjs(date).format(options.format);
};


/**
 * Converts string to dayjs date with specific format and returns it.
 * @param date
 * @param currentFormat
 * @return {dayjs.Dayjs}
 */
export const convertStringToDate = (date, currentFormat) => dayjs(date, currentFormat);


export const getPartOfDate = (date, format = 'DD/MM/YYYY') => dayjs(date).format(format);

export const convertToDate = date => new Date(dayjs(date));


/**
 * Get current year if year is undefined otherwise return year that inputted.
 * @param year
 * @return {dayjs.Dayjs}
 */
export const getYear = (year = undefined) => dayjs().year(year);

/**
 * Get current month if month is undefined otherwise return month that inputted.
 * @param month
 * @return {dayjs.Dayjs}
 */
export const getMonth = (month = undefined) => dayjs().month(month);


export const findMaxDate = (dates) => {
  let newDates;
  newDates = dates.filter(date => date);
  newDates = newDates.map(date => convertToDate(date).getTime());

  return new Date(Math.max(...newDates));
};


export const verboseDate = (date) => {
  const dayOfWeek = getPartOfDate(date, 'ddd.');
  const dayAndMonth = getPartOfDate(date, 'D MMM.');
  const year = getPartOfDate(date, 'YYYY');
  const hour = getPartOfDate(date, 'HH:mm');

  const isInThisYear = year === getPartOfDate(new Date(), 'YYYY');

  let formattedDate;
  if (isInThisYear) {
    formattedDate = `${dayOfWeek} ${dayAndMonth} ${hour}`;
  } else {
    formattedDate = `${dayAndMonth} ${year}`;
  }

  return formattedDate;
};

export const firstDayOfWeek = (date) => {
  dayjs.updateLocale('en', {
    weekStart: 1,
    yearStart: 4, // The week that contains Jan 4th is the first week of the year.
  });

  return dayjs(backendDateFormat(date)).startOf('week');
};

export const firstDayOfMonth = (date) => {
  dayjs.updateLocale('en', {
    weekStart: 1,
    yearStart: 4, // The week that contains Jan 4th is the first week of the year.
  });

  return dayjs(backendDateFormat(date)).startOf('month');
};


export const lastDayOfMonth = (date) => {
  dayjs.updateLocale('en', {
    weekStart: 1,
    yearStart: 4, // The week that contains Jan 4th is the first week of the year.
  });

  return dayjs(backendDateFormat(date)).endOf('month');
};


export const lastDayOfWeek = (date) => {
  dayjs.updateLocale('en', {
    weekStart: 1,
    yearStart: 4, // The week that contains Jan 4th is the first week of the year.
  });

  return dayjs(backendDateFormat(date)).endOf('week');
};

export const withinTheRange = (date, start, end, conditions) => dayjs(backendDateFormat(date)).isBetween(backendDateFormat(start), backendDateFormat(end), conditions);

export const getWeekOfYear = (date) => {
  dayjs.updateLocale('en', {
    weekStart: 1,
    yearStart: 4, // The week that contains Jan 4th is the first week of the year.
  });

  return dayjs(backendDateFormat(date)).week();
};

export const getMonthOfYear = (date) => {
  const monthOfYear = dayjs(date).month(); // It returns January as 0.

  return monthOfYear + 1;
};

export const isWeekday = () => {
  const now = dayjs();
  const dayOfWeek = now.day();

  return dayOfWeek >= 1 && dayOfWeek <= 5;
};

export const isBankHoliday = () => {
  const today = dayjs().format('YYYY-MM-DD');
  return BankHolidaysConstants.BANK_HOLIDAYS.includes(today);
};

export const calculateAvailableHours = (startHour, endHour) => {
  const now = dayjs();
  const start = dayjs().hour(startHour).minute(0);
  const end = dayjs().hour(endHour).minute(0);

  return now.isBetween(start, end, null, '[]');
};

export const getMonthOfYearISO = date => dayjs(date).month();

export const isAfter = date => dayjs().isAfter(date);

export const isAfterFromDate = (firstDate, secondDate) => dayjs(firstDate).isAfter(secondDate);

export const isBeforeFromDate = (firstDate, secondDate) => dayjs(firstDate).isBefore(secondDate);


export const addTimeToDate = (date, value, type = 'm') => dayjs(date).add(value, type);

export const subtractDate = (date, amount, type) => dayjs(date).subtract(amount, type);

export const addDate = (date, amount, type) => dayjs(date).add(amount, type);

export const dateDiff = (leftHandDate, rightHandDate, type, float) => dayjs(leftHandDate).diff(dayjs(rightHandDate), type, float);

export const isSame = (date, secondDate, by = 'day') => dayjs(date).isSame(secondDate, by);

export const formatWithOrdinalDay = date => dayjs(date).format('Do MMMM YYYY');

export const hourFormatWithAmPm = date => dayjs(date).format('hh:mm a');

export const dayOfWeek = date => dayjs(date).day();

export const isoDayOfWeek = date => dayjs(date).isoWeekday();

export const isoWeekDay = date => dayjs().isoWeekday(date);

export const dateOfMonth = date => dayjs(date).date();

export const dateOfMonthObj = (date, val) => dayjs(date).date(val);

export const numberOfDaysInMonth = date => dayjs(date).daysInMonth();

export const nameOfMonth = date => dayjs.months()[dayjs(date).month()];

export const nameOfDay = date => dayjs.weekdays()[dayjs(date).day()];
