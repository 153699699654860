import ApiService from '@/core/api/api.service';

export class UzairServices extends ApiService {
  setApiType() {
    this.apiType = 'VUE_APP_SEARCH_ENGINE_API';
  }

  async talkWithAI(payload) {
    this.loader = false;
    this.payload = payload;
    this.url = 'uzair/talks';
    const { result: { data } } = await this.post();
    return data;
  }

  async getTalks(params) {
    this.loader = false;
    this.params = params;
    this.url = 'uzair/talks';
    const { result: { data, meta } } = await this.get();
    return { data, meta };
  }

  async getTalk(id) {
    this.loader = false;
    this.url = `uzair/talks/${id}`;
    const { result: { data } } = await this.get();
    return data;
  }

  async updateTalk(id, payload) {
    this.loader = false;
    this.url = `uzair/talks/${id}`;
    this.payload = payload;
    const { result: { data } } = await this.patch();
    return data;
  }

  /**
   * @param {string} id
   * @return {Promise<*>}
   */
  deleteTalk(id) {
    this.url = `uzair/talks/${id}`;
    return this.delete();
  }

  async getContentSuggestions(params = {}) {
    this.params = params;
    this.url = 'uzair/contents/suggestions';
    this.loader = false;

    const { result: { data, meta } } = await this.get();
    return { data, meta };
  }

  async buyCredit(payload) {
    this.payload = payload;
    this.url = 'uzair/credits';
    this.loader = false;

    const { result: { data } } = await this.post();
    return data;
  }

  async startSetupProcess(payload) {
    this.url = `uzair/products/${payload.product_id}/setup`;
    this.loader = false;

    const { result: { data } } = await this.post();
    return data;
  }

  async launchUzair(payload) {
    this.url = `uzair/products/${payload.product_id}/launch`;
    this.loader = false;

    const { result: { data } } = await this.post();
    return data;
  }

  async getPropertyProfileForUzair(propertyProfileId, talkName) {
    this.loader = false;
    this.url = `uzair/property_profiles/${propertyProfileId}`;
    this.params = {
      talk_name: talkName,
    };

    const { result: { data } } = await this.get();
    return data;
  }
}
